@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Fustat:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

body {
  font-family: "Fustat", sans-serif;
}
